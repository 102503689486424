import {DataContainer} from "@otto-ec/tracking-bct";

export class TrackingWrapper {

    static initialize(): void {
        const o_order = window.o_order ?? {};

        o_order.trackingWrapper = o_order.trackingWrapper ?? new TrackingWrapper();
    }

    failSafeSendEventToTrackingServer(data: DataContainer): void {
        try {
            if (!window.o_order.settings.service.isEnabled('EPIC_TRACKING_QUIETSCHTEST')) {
                window.o_tracking.bct.sendEventToTrackingServer(data);
            }
        } catch (e) {
            console.log("Failed to call o_tracking.bct.sendEventToTrackingServer", e);
        }
    }

    failSafeSendMergeToTrackingServer(data: DataContainer): void {
        try {
            if (!window.o_order.settings.service.isEnabled('EPIC_TRACKING_QUIETSCHTEST')) {
                window.o_tracking.bct.sendMergeToTrackingServer(data);
            }
        } catch (e) {
            console.log("Failed to call o_tracking.bct.sendMergeToTrackingServer", e);
        }
    }

    failSafeCreateContext(layerId: string, layerUrl: string): void {
        try {
            if (!window.o_order.settings.service.isEnabled('EPIC_TRACKING_QUIETSCHTEST')) {
                window.o_tracking.bct.createContext(layerId, layerUrl, {});
            }
        } catch (e) {
            console.log("Failed to call o_tracking.bct.createContext", e);
        }
    }

    failSafeCloseContext(): void {
        try {
            if (!window.o_order.settings.service.isEnabled('EPIC_TRACKING_QUIETSCHTEST')) {
                window.o_tracking.bct.closeContext();
            }
        } catch (e) {
            console.log("Failed to call o_tracking.bct.closeContext", e);
        }
    }
}