export class Utils {

    inArray(elem: any, array: any): number {
        if ('indexOf' in array) {
            return array.indexOf(elem);
        }

        if (Array.isArray(array)) {
            for (let i = 0, length = array.length; i < length; i++) {
                if (array[i] === elem) {
                    return i;
                }
            }
        }

        return -1;
    }

    next(element: Element, clazz: string): Element | undefined {
        let nextElement: Element | null = element;
        if (nextElement) {
            do {
                nextElement = nextElement.nextElementSibling;
            } while (nextElement && !nextElement.classList.contains(clazz));
        }
        return nextElement ? nextElement : undefined;
    }

    parseJsonOrReturn<T>(something: string): T | string {
        try {
            return JSON.parse(something);
        } catch (e) {
            return something;
        }
    }

    parseJson<T>(something: string): T | Record<string, never> {
        try {
            return JSON.parse(something) || {};
        } catch (e) {
            return {};
        }
    }

    parseJsonArray<T>(input: string): T[] {
        try {
            return JSON.parse(input);
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    toggle(el: HTMLElement, value: string): void {
        const display = getComputedStyle(el, null).display;
        if (display === 'none') {
            el.style.display = value;
        } else {
            el.style.display = 'none';
        }
    }

    handleAjax(success: (xhr: XMLHttpRequest) => void, error: (xhr: XMLHttpRequest) => void): (xhr: XMLHttpRequest) => void {
        return (xhr: XMLHttpRequest) => {
            if (xhr.status >= 200 && xhr.status < 300 || xhr.status === 304) {
                success(xhr);
            } else if (xhr.status === 401) {
                /*🤫*/
                const redirectTo = this.readResponseRedirectTo(xhr);
                if (redirectTo) {
                    window.location.href = redirectTo;
                }
            } else {
                if (error && typeof error === "function") {
                    error(xhr);
                }
            }
        }
    }

    handleAjaxSuccessRedirect(xhr: XMLHttpRequest,
                              /*🤫*/
                              beforeRedirect: () => unknown = () => {
                              }): boolean {
        const redirectTo = this.readResponseRedirectTo(xhr);
        if (redirectTo) {
            beforeRedirect();
            this.goto(window, redirectTo);
            return true;
        }
        return false;
    }

    /*🤫
🤫
🤫
🤫
🤫
🤫
🤫
🤫
🤫*/
    goto(window: Window, url: string): void {
        function extractUrlWithoutHash(urlWithMaybeHash: string) {
            const hashSignPosition = urlWithMaybeHash.indexOf("#");
            return hashSignPosition > -1 ? urlWithMaybeHash.substring(0, hashSignPosition) : urlWithMaybeHash;
        }


        const newUrlElement = document.createElement('a')

        newUrlElement.href = url
        const currentUrlWithoutHash = extractUrlWithoutHash(window.location.href);
        const newUrlWithoutHash = extractUrlWithoutHash(newUrlElement.toString());

        if (currentUrlWithoutHash === newUrlWithoutHash) {
            window.location.hash = newUrlElement.hash;
            window.location.reload();
        } else {
            window.location.href = url;
        }
    }

    preload(element: HTMLElement): void {
        window.preload_polyfill_invoke(element);
    }

    snakeToCamel(word: string): string {
        const lowerCaseMethod = word.toLowerCase();
        return lowerCaseMethod.charAt(0).toUpperCase() +
            lowerCaseMethod.slice(1).replace(/_([a-z])/g, function (m, group) {
                return group.toUpperCase();
            });
    }

    onAccordionToggled(dataAccordionType: string, handler: (b: boolean) => void): void {
        window.o_global.eventQBus.on('assets.accordion.toggled', (event: Event) => {
            const target = event.target as HTMLElement | null;
            if (target !== null && target.dataset.accordionType === dataAccordionType) {
                const isOpen = target.classList.contains('pl_accordion__header--open');
                handler(isOpen);
            }
        });
    }

    readResponseRedirectTo(xhr: XMLHttpRequest): string | null {
        return xhr.getResponseHeader('X-RedirectTo')
    }

    activateFeatureCompleted(featureName: string): void {
        const jsFeatureTesting = document.getElementsByClassName("order_js_featureComplete")[0];
        if (jsFeatureTesting) {
            jsFeatureTesting.setAttribute("data-" + featureName, "true");
        }
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};

        o_order.utils = o_order.utils ?? new Utils();
    }
}

declare global {
    interface Window {
        preload_polyfill_invoke: (element: HTMLElement) => void
    }
}


