import {Action, ActionName, DataContainer, Feature, TrackingQBusEvents} from "@otto-ec/tracking-bct";
import {QBus} from "@otto-ec/event-q-bus";

export class ActionTracking {

    private eventQBus: QBus<TrackingQBusEvents>;

    constructor(eventQBus: QBus<TrackingQBusEvents>) {
        this.eventQBus = eventQBus;
    }

    private randomEventMergeId(): string {
        const crypto = window.crypto;
        const validChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        return Array(10).fill(null)
            .reduce(x => x + validChars[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * validChars.length)], '');
    }

    createEventMergeId(generateId: boolean): string {
        if (generateId) {
            return this.randomEventMergeId();
        }

        return this.getEventMergeId() ?? this.randomEventMergeId();
    }

    getEventMergeId(): string | undefined {
        return document.querySelector<HTMLElement>('.or_js_eventMergeId')?.dataset.eventMergeId;
    }

    addFeaturesToPageImpression(features: Feature[]): void {
        this.eventQBus.emit('tracking.bct.addFeaturesToPageImpression', features);
    }

    addToPageImpression(data: DataContainer): void {
        this.eventQBus.emit('tracking.bct.addToPageImpression', data);
    }

    addActionToEvent(action: Action, generateId: boolean = false): void {
        const eventMergeId = this.createEventMergeId(generateId);
        this.eventQBus.emit('tracking.bct.addActionToEvent', action, eventMergeId);
    }

    submitAction(action: Action, labels: DataContainer = {}): void {
        this.eventQBus.emit('tracking.bct.submitAction', labels, action);
    }

    submitMoveAction(action: Action, labels: DataContainer = {}): void {
        this.eventQBus.emit('tracking.bct.submitMoveAction', labels, action);
    }

    addDummyActionToEvent(name: ActionName, eventMergeId: string): void {
        this.eventQBus.emit('tracking.bct.addActionToEvent', {name: name, features: []}, eventMergeId);
    }

    paymentMethodSelection(method: string, initialMethod: string, retailerId: string, generateId: boolean = false): void {
        if (method === initialMethod) {
            return;
        }

        const camelCaseMethod = window.o_order.utils.snakeToCamel(method);

        this.addActionToEvent({
            name: 'select',
            features: [
                {
                    id: 'order_payment_sel',
                    name: parseInt(retailerId, 10) === 0 ? 'PaymentSelectOtto' : 'PaymentSelectMarket',
                    status: 'selected',
                    labels: {
                        "order_PaymentSelect": [camelCaseMethod]
                    }
                }
            ]
        }, generateId);
    }

    createContext(layerId: string, layerUrl: string): void {
        if (window.o_order.settings.service.isEnabled('EPIC_TRACKING_QUIETSCHTEST')) {
            this.eventQBus.emit('tracking.bct.createContext', layerId, layerUrl);
        }
    }

    closeContext(): void {
        if (window.o_order.settings.service.isEnabled('EPIC_TRACKING_QUIETSCHTEST')) {
            this.eventQBus.emit('tracking.bct.closeContext');
        }
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};
        o_order.tracking = o_order.tracking ?? new ActionTracking(window.o_global.eventQBus);
    }
}
